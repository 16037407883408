import React from 'react';

import { SearchFormComponent as SearchFormComponentType } from '@AuroraTypes';
import { NewSearchForm } from '@Components/SearchForm/NewSearchForm';
import { SearchConfigurationContext } from '@Components/SearchForm/SearchConfigurationContext';
import { useInitialSearchSelection } from '@Core/useInitialSearchSelection';
import { LandingSearchAvailabilityStoreUpdater } from '@Pages/landing/store-updaters/LandingSearchAvailabilityStoreUpdater';
import { StoreOverride } from '@Stores/StoreContext';

export const SearchFormComponent: React.FC<Omit<SearchFormComponentType, 'type'>> = ({
  searchForm,
}) => {
  const initialSearchSelection = useInitialSearchSelection();

  return (
    <StoreOverride
      initialValues={{
        searchSelection: {
          ...initialSearchSelection,
          filters: undefined,
          sort: undefined,
        },
      }}
    >
      <LandingSearchAvailabilityStoreUpdater storeSelectionInCookie>
        <SearchConfigurationContext.Provider
          value={{
            showFullFormMobile: true,
            showSearchButton: true,
            useClientSideNavigation: true,
            isFlexibilityEnabled: true,
            ...searchForm,
          }}
        >
          <NewSearchForm sx={{ marginTop: ['xs', 0] }} />
        </SearchConfigurationContext.Provider>
      </LandingSearchAvailabilityStoreUpdater>
    </StoreOverride>
  );
};
